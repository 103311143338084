import LazyImage from "../../utils/lazy-image";
import BlogImg from "../../assets/img/blog-card-048b22.jpg";
export default function AllBlogs() {
  return (
    <div className="rbt-blog-area rbt-section-overlayping-top rbt-section-gapBottom">
      <div className="container">
        <div className="row g-5 mt--15">
          <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="rbt-card variation-02 rbt-hover">
              <div className="rbt-card-img">
                <a href="/blog-details/25">
                  <LazyImage alt="Card" dim={"267-450"} src={BlogImg} />
                </a>
              </div>
              <div className="rbt-card-body">
                <h5 className="rbt-card-title">
                  <a href="/blog-details/25">React</a>
                </h5>
                <p className="rbt-card-text">
                  It is a long established fact that a reader.
                </p>
                <div className="rbt-card-bottom">
                  <a className="transparent-button" href="/blog-details/25">
                    Learn More
                    <i>
                      <svg
                        width="17"
                        height="12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g stroke="#27374D" fill="none" fillRule="evenodd">
                          <path d="M10.614 0l5.629 5.629-5.63 5.629"></path>
                          <path
                            strokeLlinecap="square"
                            d="M.663 5.572h14.594"
                          ></path>
                        </g>
                      </svg>
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="rbt-card variation-02 rbt-hover">
              <div className="rbt-card-img">
                <a href="/blog-details/26">
                  <LazyImage alt="Card" dim={"267-450"} src={BlogImg} />
                </a>
              </div>
              <div className="rbt-card-body">
                <h5 className="rbt-card-title">
                  <a href="/blog-details/26">Why Is Education So Famous?</a>
                </h5>
                <p className="rbt-card-text"></p>
                <div className="rbt-card-bottom">
                  <a className="transparent-button" href="/blog-details/26">
                    Learn More
                    <i>
                      <svg
                        width="17"
                        height="12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g stroke="#27374D" fill="none" fillRule="evenodd">
                          <path d="M10.614 0l5.629 5.629-5.63 5.629"></path>
                          <path
                            strokeLlinecap="square"
                            d="M.663 5.572h14.594"
                          ></path>
                        </g>
                      </svg>
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="rbt-card variation-02 rbt-hover">
              <div className="rbt-card-img">
                <a href="/blog-details/27">
                  <LazyImage alt="Card" dim={"267-450"} src={BlogImg} />
                </a>
              </div>
              <div className="rbt-card-body">
                <h5 className="rbt-card-title">
                  <a href="/blog-details/27">
                    Difficult Things About Education.
                  </a>
                </h5>
                <p className="rbt-card-text"></p>
                <div className="rbt-card-bottom">
                  <a className="transparent-button" href="/blog-details/27">
                    Learn More
                    <i>
                      <svg
                        width="17"
                        height="12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g stroke="#27374D" fill="none" fillRule="evenodd">
                          <path d="M10.614 0l5.629 5.629-5.63 5.629"></path>
                          <path
                            strokeLlinecap="square"
                            d="M.663 5.572h14.594"
                          ></path>
                        </g>
                      </svg>
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="rbt-card variation-02 rbt-hover">
              <div className="rbt-card-img">
                <a href="/blog-details/28">
                  <LazyImage alt="Card" dim={"267-450"} src={BlogImg} />
                </a>
              </div>
              <div className="rbt-card-body">
                <h5 className="rbt-card-title">
                  <a href="/blog-details/28">
                    Education Is So Famous, But Why?
                  </a>
                </h5>
                <p className="rbt-card-text"></p>
                <div className="rbt-card-bottom">
                  <a className="transparent-button" href="/blog-details/28">
                    Learn More
                    <i>
                      <svg
                        width="17"
                        height="12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g stroke="#27374D" fill="none" fillRule="evenodd">
                          <path d="M10.614 0l5.629 5.629-5.63 5.629"></path>
                          <path
                            strokeLlinecap="square"
                            d="M.663 5.572h14.594"
                          ></path>
                        </g>
                      </svg>
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="rbt-card variation-02 rbt-hover">
              <div className="rbt-card-img">
                <a href="/blog-details/29">
                  <LazyImage alt="Card" dim={"267-450"} src={BlogImg} />
                </a>
              </div>
              <div className="rbt-card-body">
                <h5 className="rbt-card-title">
                  <a href="/blog-details/29">The Latest Trend In Education.</a>
                </h5>
                <p className="rbt-card-text">
                  It is a long established fact that a reader.
                </p>
                <div className="rbt-card-bottom">
                  <a className="transparent-button" href="/blog-details/29">
                    Learn More
                    <i>
                      <svg
                        width="17"
                        height="12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g stroke="#27374D" fill="none" fillRule="evenodd">
                          <path d="M10.614 0l5.629 5.629-5.63 5.629"></path>
                          <path
                            strokeLlinecap="square"
                            d="M.663 5.572h14.594"
                          ></path>
                        </g>
                      </svg>
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="rbt-card variation-02 rbt-hover">
              <div className="rbt-card-img">
                <a href="/blog-details/30">
                  <LazyImage alt="Card" dim={"267-450"} src={BlogImg} />
                </a>
              </div>
              <div className="rbt-card-body">
                <h5 className="rbt-card-title">
                  <a href="/blog-details/30">The Modern Rules Of Education.</a>
                </h5>
                <p className="rbt-card-text">
                  It is a long established fact that a reader.
                </p>
                <div className="rbt-card-bottom">
                  <a className="transparent-button" href="/blog-details/30">
                    Learn More
                    <i>
                      <svg
                        width="17"
                        height="12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g stroke="#27374D" fill="none" fillRule="evenodd">
                          <path d="M10.614 0l5.629 5.629-5.63 5.629"></path>
                          <path
                            strokeLlinecap="square"
                            d="M.663 5.572h14.594"
                          ></path>
                        </g>
                      </svg>
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mt--60">
            <nav>
              <div className="nav-links">
                <ul className="rbt-pagination">
                  <li className="">
                    <a aria-label="Previous" href="/blog-grid#">
                      <i className="feather-chevron-left"></i>
                    </a>
                  </li>
                  <li className="">
                    <a href="/blog-grid#">1</a>
                  </li>
                  <li className="active">
                    <a href="/blog-grid#">2</a>
                  </li>
                  <li className="disabled">
                    <a aria-label="Next" href="/blog-grid#">
                      <i className="feather-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
