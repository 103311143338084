import Img from "../../assets/img/Individual.png";
import Img2 from "../../assets/img/couple.png";
import Img3 from "../../assets/img/teen.png";
import bgImg1 from "../../assets/img/individualbg.png";
import bgImg2 from "../../assets/img/couplebg.png";
import bgImg3 from "../../assets/img/teenbg.png";
import LazyImage from "../../utils/lazy-image";
export default function PlansCards() {
  return (
    <div class="rbt-article-content-wrapper">
      <div class="row mb--60 mt--60">
        <div class="col-lg-12">
          <div class="section-title text-center">
            <h4 class="title"><span className="theme-gradient">Counselling & Therapy Plans </span></h4>
            <p>Our counseling and therapy plans offer tailored support to meet your unique needs.</p>
          </div>
        </div>
      </div>
      <div class="row row--15 mt_dec--30">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt--30">
          <div class="rbt-flipbox variation-2">
            <div class="rbt-flipbox-wrap rbt-service rbt-service-1 card-bg-1">
              <div class="rbt-flipbox-front rbt-flipbox-face inner">
                <div class="front-thumb w-100">
                  <LazyImage alt="card-icon" dim={"300-490"} src={Img} />
                </div>
                <div class="content">
                  <h5 class="title">
                    <a href="/elements/service#">Individual Counselling (For Myself)</a>
                  </h5>
                  <ul class="rbt-list-style-3">
                  <li>
    <i class="feather-check"></i> Work-Related Stress
  </li>
  <li>
    <i class="feather-check"></i> Burnout Prevention
  </li>
  <li>
    <i class="feather-check"></i> Emotional Support
  </li>
  <li>
    <i class="feather-check"></i> Career Transitions
  </li>
  <li>
    <i class="feather-check"></i> Stress Management
  </li>
  <li>
    <i class="feather-check"></i> Anxiety and Depression
  </li>
  <li>
    <i class="feather-check"></i> Relationship Issues
  </li>
  <li>
    <i class="feather-check"></i> Self-Esteem Building
  </li>
  <li>
    <i class="feather-check"></i> Coping with Loss and Grief
  </li>
  <li>
    <i class="feather-check"></i> Personal Growth and Development
  </li>
                  </ul>
                  <a
                    class="rbt-btn-link stretched-link"
                    href="/elements/service#"
                  >
                    Select Now<i class="feather-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="rbt-flipbox-back rbt-flipbox-face inner">
                <div class="flip-back-top">
                  <div class="back-thumb w-100">
                    <LazyImage alt="card-icon" dim={"300-490"} src={bgImg1} />
                  </div>
                  <p>
                  Individual counseling offers a unique opportunity to explore and resolve personal challenges. 
                  With customized sessions and proven therapeutic techniques, 
                  therapists work with you to foster a healthier, more fulfilling life.
                  </p>
                </div>
                <a
                  class="rbt-btn rbt-switch-btn btn-white btn-sm"
                  href="/elements/service#"
                >
                  <span data-text="Select Now">Select Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt--30">
          <div class="rbt-flipbox variation-2">
            <div class="rbt-flipbox-wrap rbt-service rbt-service-1 card-bg-2">
              <div class="rbt-flipbox-front rbt-flipbox-face inner">
                <div class="front-thumb w-100">
                  <LazyImage alt="card-icon" dim={"300-490"} src={Img2} />
                </div>
                <div class="content">
                  <h5 class="title">
                    <a href="/elements/service#">Couple Counselling (For Myself and My Partner)</a>
                  </h5>
                  <ul class="rbt-list-style-3">
                  <ul>
  <li>
    <i class="feather-check"></i> Strengthened Emotional Bond
  </li>
  <li>
    <i class="feather-check"></i> Conflict Resolution
  </li>
  <li>
    <i class="feather-check"></i> Rebuilding Trust
  </li>
  <li>
    <i class="feather-check"></i> Enhanced Mutual Respect and Appreciation
  </li>
  <li>
    <i class="feather-check"></i> Understanding Patterns and Dynamics
  </li>
  <li>
    <i class="feather-check"></i> Improving Communication Skills
  </li>
  <li>
    <i class="feather-check"></i> Strengthening Partnership and Teamwork
  </li>
  <li>
    <i class="feather-check"></i> Navigating Life Transitions Together
  </li>
  <li>
    <i class="feather-check"></i> Managing Stress as a Couple
  </li>
  <li>
    <i class="feather-check"></i> Setting and Achieving Shared Goals
  </li>
</ul>

                  </ul>
                  <a
                    class="rbt-btn-link stretched-link"
                    href="/elements/service#"
                  >
                    Select Now<i class="feather-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="rbt-flipbox-back rbt-flipbox-face inner">
                <div class="flip-back-top">
                  <div class="back-thumb w-100">
                    <LazyImage alt="card-icon" dim={"300-490"} src={bgImg3} />
                  </div>
                  <p>
                  Couple counseling offers a unique opportunity to strengthen your relationship by addressing and resolving conflicts. Our therapists work with you and your partner to enhance communication, 
                  rebuild trust, and foster a healthier, more fulfilling partnership.
                  </p>
                </div>
                <a
                  class="rbt-btn rbt-switch-btn btn-white btn-sm"
                  href="/elements/service#"
                >
                  <span data-text="Select Now">Select Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt--30">
          <div class="rbt-flipbox variation-2">
            <div class="rbt-flipbox-wrap rbt-service rbt-service-1 card-bg-3">
              <div class="rbt-flipbox-front rbt-flipbox-face inner">
                <div class="front-thumb w-100">
                  <LazyImage alt="card-icon" dim={"300-490"} src={Img3} />
                </div>
                <div class="content">
                  <h5 class="title">
                    <a href="/elements/service#">Teen Counseling (For My Child)</a>
                  </h5>
                  <ul class="rbt-list-style-3">
                  <li>
    <i class="feather-check"></i> Managing Academic Stress
  </li>
  <li>
    <i class="feather-check"></i> Coping with Peer Pressure
  </li>
  <li>
    <i class="feather-check"></i> Building Self-Esteem and Confidence
  </li>
  <li>
    <i class="feather-check"></i> Navigating Social Relationships
  </li>
  <li>
    <i class="feather-check"></i> Addressing Anxiety and Depression
  </li>
  <li>
    <i class="feather-check"></i> Developing Healthy Coping Mechanisms
  </li>
  <li>
    <i class="feather-check"></i> Understanding and Managing Emotions
  </li>
  <li>
    <i class="feather-check"></i> Enhancing Communication Skills
  </li>
  <li>
    <i class="feather-check"></i> Support for Family Issues
  </li>
  <li>
    <i class="feather-check"></i> Goal Setting and Future Planning
  </li>
                  </ul>
                  <a
                    class="rbt-btn-link stretched-link"
                    href="/elements/service#"
                  >
                    Select Now<i class="feather-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="rbt-flipbox-back rbt-flipbox-face inner">
                <div class="flip-back-top">
                  <div class="back-thumb w-100">
                    <LazyImage alt="card-icon" dim={"300-490"} src={bgImg2} />
                  </div>
                  <p>
                  Teen counseling provides a safe and supportive space for adolescents to explore and resolve their unique challenges. Our therapists work with teens to improve emotional well-being, and develop coping skills.
                  </p>
                </div>
                <a
                  class="rbt-btn rbt-switch-btn btn-white btn-sm"
                  href="/elements/service#"
                >
                  <span data-text="Select Now">Select Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
