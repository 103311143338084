import InvoicesHeader from "../../components/therapists/invoices/invoices-header";
import MainLayout from "../../components/therapists/main-layout";

export default function Invoices() {
  return (
    <>
      <MainLayout>
        <InvoicesHeader />
      </MainLayout>
    </>
  );
}
