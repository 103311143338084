import MainLayout from "../../components/therapists/main-layout";
import ReviewsSidebar from "../../components/therapists/Reviews/reviews-sidebar";

export default function Reviews() {
  return (
    <>
      <MainLayout>
        <ReviewsSidebar />
      </MainLayout>
    </>
  );
}
