export const stateList = [
  "Select",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
  "Jammu and Kashmir",
  "Ladakh",
];

export const profileTypeList = [
  "Counselling Psychologist",
  "Clinical Psychologist",
  "Psychiatrist",
  "Special Educator",
];
export const counsellingTypes = [
  "Individual Counselling",
  "Couple Counselling",
  "Teen Counselling",
];

export const ExpList = [
  "Select",
  "1-2 Years",
  "3-5 Years",
  "6-10 Years",
  "11-15 Years",
  "16-20 Years",
  "21+ Years",
];

export const services = [
  "Individual Counselling",
  "Couple Counselling",
  "Teen Counselling",
  "Diagnosis",
  "Workshops/Training",
];

export const EducationList = [
  "Select",
  "Diploma in Counseling/Psychology",
  "Bachelor's Degree (B.A/B.Sc in Psychology)",
  "Bachelor's Degree (B.Ed in Special Education)",
  "Master's Degree (M.A/M.Sc in Psychology)",
  "Master's Degree (M.Ed in Special Education)",
  "Master of Philosophy (M.Phil in Clinical Psychology)",
  "Doctor of Philosophy (Ph.D. in Psychology)",
  "Doctor of Medicine (M.D. in Psychiatry)",
  "Doctor of Psychology (Psy.D.)",
  "Postgraduate Diploma in Counseling Psychology",
  "Postgraduate Diploma in Special Education",
  "Other (Please specify)",
];

export const dailyLiftIssuesList = [
  "Stress Management",
  "Anxiety",
  "Depression",
  "Self Esteem",
  "Time Management",

  "Work Life Balance",
  "Burnout",
  "Life Transitions",
  "Grief And Loss",
  "Anger Management",
  "Motivation",
];

export const therapyoptionlist = [
  "Cognitive Behavioural Therapy (CBT)",
  "Dialectical Behavioural Therapy (DBT)",
  "Psychodynamic Therapy",
  "Humanistic Therapy",
  "Mindfulness-Based Therapy",
  "Solution-Focused Brief Therapy (SFBT)",
  "Acceptance and Commitment Therapy (ACT)",
  "Eye Movement Desensitization and Reprocessing (EMDR)",
  "Art Therapy",
  "Play Therapy",
  "Group Therapy",
  "Family Therapy",
  "Narrative Therapy",
  "Interpersonal Therapy (IPT)",
];

export const diagnoseslist = [
  "Generalized Anxiety Disorder (GAD)",
  "Major Depressive Disorder (MDD)",
  "Bipolar Disorder",
  "Obsessive-Compulsive Disorder (OCD)",
  "Post-Traumatic Stress Disorder (PTSD)",
  "Attention Deficit Hyperactivity Disorder (ADHD)",
  "Autism Spectrum Disorder (ASD)",
  "Schizophrenia",
  "Personality Disorders",
  "Eating Disorders",
];

export const relationshipIssuesList = [
  "Marital Counselling",
  "Family Therapy",
  "Parent-Child Relationship",
  "Couples Counselling",
  "Divorce and Separation",
  "Conflict Resolution",
  "Communication Issues",
  "Trust Issues",
  "Intimacy Issues",
  "Pre-Marital Counselling",
];

export const languageSpoken = [
  { value: "Hindi", label: "Hindi" },
  { value: "Tamil", label: "Tamil" },
  { value: "Telugu", label: "Telugu" },
  { value: "Marathi", label: "Marathi" },
  { value: "Bengali", label: "Bengali" },
  { value: "Kannada", label: "Kannada" },
  { value: "Malayalam", label: "Malayalam" },
  { value: "Gujarati", label: "Gujarati" },
  { value: "Punjabi", label: "Punjabi" },
  { value: "English", label: "English" },
];

export const eventDuration = [
  "30 Minute",
  "01 Hour",
  "1.5 Hour",
  "02 Hour",
  "2.5 Hour",
  "03 Hour",
  "3.5 Hour",
  "04 Hour",
  "4.5 Hour",
  "05 Hour",
  "Onwards"
];

export const sessionFormatsList = ["In-Person", "Audio call", "Video Call"];

export const allTimes = [
  "12:00am",
  "01:00am",
  "01:30am",
  "02:00am",
  "02:30am",
  "03:00am",
  "03:30am",
  "04:00am",
  "04:30am",
  "05:00am",
  "05:30am",
  "06:00am",
  "06:30am",
  "07:00am",
  "07:30am",
  "08:00am",
  "08:30am",
  "09:00am",
  "09:30am",
  "10:00am",
  "10:30am",
  "11:00am",
  "11:30am",
  "12:00pm",
  "01:00pm",
  "01:30pm",
  "02:00pm",
  "02:30pm",
  "03:00pm",
  "03:30pm",
  "04:00pm",
  "04:30pm",
  "05:00pm",
  "05:30pm",
  "06:00pm",
  "06:30pm",
  "07:00pm",
  "07:30pm",
  "08:00pm",
  "08:30pm",
  "09:00pm",
  "09:30pm",
  "10:00pm",
  "10:30pm",
  "11:00pm",
  "11:30pm",
];

export const PriceList = ["500-1000", "1000-1500", "1500-2000", "2000-2500"];

export const WorkshopCategory = [
  "Support Groups",
  "Student Orientations",
  "Workplace Training",
  "Therapeutic Activities",
];
